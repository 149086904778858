import { ref } from "vue";
import { defineStore } from "pinia";

export const usePageStyleStore = defineStore("pageStyle", () => {
  const color = ref<any>("");
  const bg = ref<any>("");
  const logo = ref<any>("");

  return { color, bg, logo };
});
