<script setup lang="ts">
import QrcodeVue from "qrcode.vue";
import { useParticipantStore } from "@/stores/eventParticipant";
import router from "@/router";
import apiClient from "@/utils/apiClient";
import { ref, onMounted } from "vue";
import dayjs from "dayjs";
import { usePageStyleStore } from "@/stores/pageStyle";
import { useRoute } from "vue-router";

const pageStyle = usePageStyleStore();
const route = useRoute();

const id = import.meta.env.VITE_EVENT_ID;
const API_URL = import.meta.env.VITE_API_URL;
const participantData = useParticipantStore();
const event = ref<any>({});

onMounted(() => {
  apiClient
    .get(
      API_URL +
        "/api/event/" +
        `${route.query.event ? `search/${route.query.event}` : id}`,
    )
    .then((response) => {
      event.value = response.data.event;
    });
});

const returnToPage = () => {
  router.push({
    path: "/",
    query: { event: route.query.event },
  });
};

const getDate = (date: any, timezone = 8) => {
  const dateCalc = dayjs(date).add(timezone, "hour").format("DD MMMM YYYY");

  return dateCalc;
};
</script>

<style scoped>
/* * {
  font-family: Gotham;
} */
</style>

<template>
  <main class="min-h-screen flex items-center justify-center flex-col pb-5">
    <div
      class="w-full lg:w-1/2 flex items-center flex-col justify-center py-5 px-5"
    >
      <h2 class="mx-auto text-lg md:text-xl text-center text-white font-bold">
        {{
          [9, 10, 11, 12].includes(event.id)
            ? "BADAN PELAYANAN NASIONAL"
            : "JEDUTHUN SALVATION MINISTRY"
        }}
      </h2>
      <img :src="event.logo_url || '#'" class="w-3/4 md:w-1/2" />
    </div>
    <p
      class="mx-auto text-center font-bold text-xl"
      :style="{ color: event.color }"
    >
      {{ event.event_name }}
    </p>

    <p class="mx-auto text-lg text-center" :style="{ color: event.color }">
      {{ getDate(event.event_start, event.timezone) }}
      -
      {{ getDate(event.event_end, event.timezone) }}
    </p>

    <h2 class="font-bold mx-auto text-center mt-5 px-4 lg:px-0 text-red-800">
      Harap screenshot halaman ini karena QR Code yang tertera akan digunakan
      untuk re-registrasi pada saat event berlangsung
    </h2>
    <div
      class="card w-80 bg-base-100 shadow-xl mx-auto my-5"
      v-for="(participant, index) in participantData.data"
      :key="index"
    >
      <div class="card-body items-center text-center">
        <div class="w-full mx-auto">
          <qrcode-vue
            :value="participant.id"
            :foreground="event.color !== '#FFFFFF' ? event.color : '#7f1d1d'"
            class="mx-auto p-2 bg-white"
            :size="200"
            render-as="svg"
          ></qrcode-vue>
        </div>
        <h2 class="card-title">
          {{ participant.name }}, Terima kasih telah register kegiatan
          {{ participantData.event.event_name }}!
        </h2>
        <p>Jenis Paket : {{ participantData.pckg.package_name }}</p>
        <div class="divider"></div>
        <p class="font-bold">
          QR code akan dipakai untuk melakukan re-registrasi di tempat
        </p>
        <p class="">
          QR code juga akan dikirimkan melalui email/WA yang anda masukkan
        </p>
        <div class="card-actions"></div>
      </div>
    </div>
    <button class="btn btn-primary" @click="returnToPage">
      Register Peserta Baru
    </button>
  </main>
</template>
