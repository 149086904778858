import axios from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
const baseURL = import.meta.env.VITE_API_URL || "http://103.176.79.182:8000";

const apiClient = () => {
  const defaultOptions = {
    baseURL,
    "Content-Type": "multipart/form-data",
    // headers: {
    //   "ngrok-skip-browser-warning":"any"
    // },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request: any) => {
    if (cookies.isKey("token")) {
      request.headers.Authorization = ` ${"Bearer " + cookies.get("token")}`;
    } else {
      request.headers.Authorization = null;
    }
    return request;
  });

  instance.interceptors.response.use((response) => {
    return response;
  });

  return instance;
};

export default apiClient();
