<script setup lang="ts">
import { RouterLink, RouterView } from "vue-router";
import { usePageStyleStore } from "@/stores/pageStyle";
import { onMounted } from "vue";

// todo: set it conditionals based on
onMounted(() => {
  const plugin = document.createElement("script");
  const isProd = import.meta.env.PROD;
  plugin.setAttribute(
    "src",
    isProd
      ? "https://app.midtrans.com/snap/snap.js"
      : "https://app.sandbox.midtrans.com/snap/snap.js"
  );
  plugin.setAttribute(
    "data-client-key",
    import.meta.env.VITE_MIDTRANS_CLIENT_KEY
  );
  plugin.async = true;
  document.head.appendChild(plugin);
});

const pageStyle = usePageStyleStore();

const name = import.meta.env.VITE_APP_NAME;
const event_name = import.meta.env.VITE_EVENT_NAME;
</script>

<!-- 
<li><RouterLink to="/">Home</RouterLink></li>
<li><RouterLink to="/about">About</RouterLink></li>
<li><RouterLink to="/register">Register Member</RouterLink></li>
 -->

<template>
  <!-- <header>
    <div class="wrapper">
      <div class="navbar bg-base-100">
        <div class="navbar-start">
          <div class="dropdown">
            <label tabindex="0" class="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
            </ul>
          </div>
          <a class="btn btn-ghost normal-case text-xl"
            >{{ name }} {{ event_name }}</a
          >
        </div>
        <div class="navbar-center hidden lg:flex">
          <ul class="menu menu-horizontal p-0">

          </ul>
        </div>
        <div class="navbar-end"></div>
      </div>
    </div>
  </header> -->
  <div
    id="wrapper"
    v-bind:style="{ 'background-image': 'url(' + pageStyle.bg + ')' }"
    class="font-mons"
  >
    <RouterView />
  </div>
</template>

<style scoped></style>
