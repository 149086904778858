<script setup lang="ts">
import { ref, onMounted, watch, watchEffect } from "vue";
import apiClient from "@/utils/apiClient";
import router from "@/router";
import { useParticipantStore } from "@/stores/eventParticipant";
import { usePageStyleStore } from "@/stores/pageStyle";
import _ from "lodash";
import { useRoute } from "vue-router";
import naraLocation from "@/assets/img/JSM Community Address-03.jpg"
import naraPotrait from "@/assets/img/JSM Community-02 (1).jpg"
import dayjs from "dayjs";

const id = import.meta.env.VITE_EVENT_ID;
const API_URL = import.meta.env.VITE_API_URL;
const KEY = import.meta.env.VITE_KEY;
const COMITTEE_URL = import.meta.env.VITE_COMITTEE_LINK;
const participantData = useParticipantStore();
const pageStyle = usePageStyleStore();
const msg = ref("");
const route = useRoute();

const room_type_option = ref([])
const checkKrk = ref(route.query?.event?.includes("catholic-revival"));
const checkNara = ref(route.query?.event?.includes("nara-medika"));
const checkPotrait = ref(screen.availHeight > screen.availWidth);

const flow = ref(['year', 'month', 'calendar']);
const format = (date: any) => {
  const day = date.getDate();
  //   const month = date.getMonth() + 1;
  const month = date.toLocaleString("id-ID", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const loading = ref(false);
const openForm = ref(false);
const questions = ref<any[]>([]);
const form = ref<any[]>([]);
const fileForm = ref<any[]>([]);
const otherOptionForm = ref<any[]>([]);
const event = ref<any>({});
const source = ref([]);
const sources = ref([
  "Teman",
  "Pasangan / Pacar",
  "Keluarga",
  "Gereja",
  "Socmed / Media Cetak",
  "Lainnya",
]);
const alreadyKnow = ref(null);
const contactConsent = ref(null);
const paymentProgress = ref(0);
const errResponse = ref<any>({});
const errCode = ref(null);
const openScanner = ref(false);
const memberUrl = ref("");
const captureValue = ref(false);
const linkedMember = ref<any>({});
const packages = ref<any[]>([]);
const pckg = ref<any>({});
const comiteeForm = ref(false);
// available state: success, loading, failed
const uploadState = ref("success");
const showModal = ref(false);
const showPaymentModal = ref(false);
const pageState = ref(1);
const promo = ref<any>({});
const paymentType = ref<any[]>([]);
const promoCode = ref("");
const checkPromoBool = ref(false);
const eventLoading = ref(false);
const eventUrl = ref("catholic-revival-2023");

const getDate = (date: any, timezone: any = 7) => {
  const dateCalc = dayjs(date).add(timezone, "hour").format("DD MMMM YYYY");

  return dateCalc;
};

const getBooleanCondition = (condition:any) => {
  return condition == true ? "1" : "0";
};
const onDecode = (memberId:any) => {
  console.log(memberId);
  memberUrl.value = memberId;
  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .get(API_URL + "/api/memberFind", {
        params: {
          member_id: memberId,
          dataKey: KEY,
        },
      })
      .then((response) => {
        linkedMember.value = response.data.member;
      })
      .catch((error) => console.log(error));
  });
};
onMounted(() => {
  eventLoading.value = true;
  loading.value = true;
  comiteeForm.value = route.path == COMITTEE_URL;
  apiClient
    .get(
      API_URL +
        "/api/event/" +
        `${eventUrl.value ? `search/${eventUrl.value}` : id}`
    )
    .then((response) => {
      if (response.data.event.event_launch == 1) {
        openForm.value = true;
      }
      event.value = response.data.event;
      pageStyle.bg = response.data.event.bg_url;
      pageStyle.logo = response.data.event.logo_url;
      pageStyle.color = response.data.event.color;
      eventLoading.value = false;
      response.data.event.payment_type.forEach((type:any) => {
        paymentType.value.push(type.alias);
      });
    })
    .then(() => {
      if (event.value.event_launch == 1) {
        apiClient
          .get(API_URL + "/api/questionEvent/" + event.value.id)
          .then((response) => {
            questions.value = response.data.question;
            resetForm(response.data.question, 1);
          });
        apiClient
          .get(API_URL + "/api/package/" + event.value.id)
          .then((response) => {
            packages.value = response.data.packages.filter((data: any) => {
              return comiteeForm.value
                ? data.show_comittee == 1
                : data.show_participant == 1;
            });
          })
          .then(() => {
            loading.value = false;
          });
      }
    })
    .catch((error) => console.log(error));
});
const resetForm = (q:any, amount:any) => {
  promo.value = {};
  promoCode.value = "";
  // reset form
  form.value = [];
  otherOptionForm.value = [];
  fileForm.value = [];
  for (let i = 0; i < amount; i++) {
    form.value.push({});
    otherOptionForm.value.push({});
    fileForm.value.push({ url: null });
    q.forEach((element:any) => {
      form.value[i][element.question?.name] = null;
      otherOptionForm.value[i][element.question?.name] = null;
      if (element.question.type == "file") {
        fileForm.value[i][element.question?.name] = {
          url: null,
        };
      }
    });
  }
};
const checkRequired = () => {
  let disabled = false;
  let reqQuestion = questions.value.filter((data) => {
    return (
      data.required == 1 &&
      (comiteeForm.value ? data.show_comittee == 1 : data.show_participant == 1)
    );
  });
  if (Object.keys(pckg.value).length == 0) disabled = true;
  for (let i = 0; i < form.value.length; i++) {
    reqQuestion.forEach((req) => {
      const q = (Object as any).entries(form.value[i]).find(([key, element]: any) => {
        return req.question.name === key;
      });
      if (q[1] === null || q[1] === "") {
        disabled = true;
      }
    });
  }
  // this is only activated if in option, user pick 'other **'
  let reqOptQuestion = questions.value.filter((data) => {
    return (
      data.required == 1 &&
      (comiteeForm.value
        ? data.show_comittee == 1
        : data.show_participant == 1) &&
      data?.question?.type == "option"
    );
  });
  for (let i = 0; i < otherOptionForm.value.length; i++) {
    reqOptQuestion.forEach((req) => {
      const [code, label] = (Object as any).entries(otherOptionForm.value[i]).find(
        ([key, element]: any) => {
          return req.question.name === key;
        }
      );
      if (
        form.value[i][code] !== null &&
        form.value[i][code]?.code.includes("other") &&
        (label === null || label === "")
      ) {
        disabled = true;
      }
    });
  }
  return disabled;
};
const checkRequiredPage = () => {
  let disabled = false;
  if (source.value.length == 0 || !alreadyKnow.value || !contactConsent.value)
    disabled = true;
  return disabled;
};
const sendNotif = () => {
  let checkEmail = questions.value.filter((data) => {
    return data.required == 1 && data.question.name == "email";
  });
  let checkPhone = questions.value.filter((data) => {
    return data.required == 1 && data.question.name == "phone_number";
  });
  if (checkEmail.length > 0) {
    apiClient
      .post(
        API_URL + "/api/participant/sendEventMailPublic/" + event.value.id,
        { code: participantData.enc }
      )
      .then((response) => {
        console.log(response);
      });
  }
  if (checkPhone.length > 0) {
    apiClient
      .post(API_URL + "/api/sendWhatsappPublic/" + event.value.id, {
        code: participantData.enc,
      })
      .then((response) => {
        console.log(response);
      });
  }
};
const sendData = () => {
  errCode.value = null;
  errResponse.value = {};
  loading.value = true;
  const promoData = {
    promo_code: promo.value?.promo_code || null,
    price_after: priceCalc(
      pckg.value.price,
      promo.value.type,
      promo.value.number
    ),
  };
  const arr = ref<any[]>([]);
  (Object as any).entries(form.value).forEach(([keyA, participant]:any) => {
    const jData = ref<any>({});
    (Object as any).entries(participant).forEach(([key, element]: any) => {
      let q = questions.value.find((data) => {
        return data.question.name === key;
      });
      if (element !== null) {
        if (q?.question?.type == "phone") {
          const x = element[0] === "0" ? element.substr(1) : element;
          jData.value[key] = "62" + x;
        } else if (q?.question.type == "option") {
          jData.value[key] = element.code.includes("other")
            ? otherOptionForm.value[keyA][key]
            : element.code;
        } else if (q?.question.type == "checkbox") {
          jData.value[key] = getBooleanCondition(element);
        } else {
          jData.value[key] = element;
        }
      }
    });
    arr.value.push(jData.value);
  });
  const sentData = {
    sentData: arr.value,
    event: event.value.id,
    package: pckg.value.id,
    promo: promoData,
    is_committee: comiteeForm.value,
    source: source.value,
    already_know: alreadyKnow.value == "No" ? false : true,
    contact_consent: contactConsent.value == "No" ? false : true,
  };
  msg.value = 'a';
  paymentProgress.value = 1;
  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/participant", sentData)
      .then((response) => {
        msg.value = 'b';
        paymentProgress.value = 2;
        participantData.event = response.data.event;
        participantData.data = response.data.participant;
        participantData.pckg = response.data.package;
        participantData.promo = response.data.promo;
        participantData.enc = response.data.enc;
        // temp: check if there's a slug, and it includes 'cyr', then don't go to payment
        // to do: check if payment price is not 0
        if (!route.query?.event?.includes("cyr") && eventUrl.value) {
          // continue payment
          if (participantData.promo?.price_after > 0) {
            apiClient
              .put(API_URL + "/api/payment/" + response.data.payment, {
                payment: {
                  id: response.data.payment,
                  total_price: promoData.price_after,
                  pckg: response.data.package,
                  type: paymentType.value,
                },
              })
              .then((response) => {
                msg.value = 'c';
                paymentProgress.value = 3;
                (window as any).snap.pay(response.data.payment.snap_token, {
                  onSuccess: function (result: any) {
                    paymentProgress.value = 0;
                    // check this one, not fully sure though
                    /* You may add your own implementation here */
                    apiClient
                      .post(
                        API_URL +
                        "/api/participant/updatePaid/" +
                        event.value.id,
                        { code: participantData.enc }
                      )
                      .then((response) => { });
                    // sendNotif();
                    router.push({
                      path: "/eventThankYou",
                      query: { event: eventUrl.value },
                    });
                    loading.value = false;
                  },
                  onPending: function (result: any) {
                    console.warn(result);
                    paymentProgress.value = 0;
                    // showPaymentModal.value = true;
                    /* You may add your own implementation here */
                  },
                  onError: function (result: any) {
                    showPaymentModal.value = true;
                    paymentProgress.value = 0;
                    /* You may add your own implementation here */
                  },
                  onClose: function () {
                    showPaymentModal.value = true;
                    paymentProgress.value = 0;
                    /* You may add your own implementation here */
                  },
                });
                // set up: update data on admin page
              });
          } else {
            /* You may add your own implementation here */
            sendNotif();
            router.push({
              path: "/eventThankYou",
              query: { event: eventUrl.value },
            });
            loading.value = false;
            apiClient
              .post(API_URL + "/api/participant/updatePaid/" + event.value.id, {
                code: participantData.enc,
              })
            // set up: update data on admin page
          }
        } else {
          router.push({
            path: "/eventThankYou",
            query: { event: eventUrl.value },
          });
          loading.value = false;
        }
      })
      .catch((err) => {
        loading.value = false;
        uploadState.value = "failed";
        showModal.value = true;
        errCode.value = err.response.status;
        errResponse.value = err.response.data;
        setTimeout(() => {
          errCode.value = null;
        }, 5000);
      });
  });
};
const checkPromo = () => {
  const form = {
    code: promoCode.value.toLocaleUpperCase(),
    package: pckg.value.id,
    event: event.value.id,
    is_committee: comiteeForm.value,
  };
  uploadState.value = "loading";
  checkPromoBool.value = true;
  showModal.value = true;
  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/promo/checkPromo", form)
      .then((response) => {
        promo.value = response.data.promo;
        uploadState.value = "success";
        setTimeout(() => {
          showModal.value = false;
          checkPromoBool.value = false;
        }, 5000);
      })
      .catch((error) => {
        errCode.value = error.response.status;
        errResponse.value = error.response.data;
        showModal.value = true;
        uploadState.value = "failed";
      });
  });
};
watch(pckg, () => {
  resetForm(questions.value, pckg?.value?.people_register);
});
watch(pckg, () => {
  if (pckg.value == null) pckg.value = {};
});
const formatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const priceCalc = (price: any, type: any, promoNum: any) => {
  switch (type) {
    case "percent":
      return price - (price * promoNum) / 100;
    case "price":
      return price - promoNum;
    default:
      return price;
  }
};
const failedPaymentCleanUp = () => {
  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/participant/destroyPublic", {
        promo_code: participantData.promo.promo_code,
        code: participantData.enc,
        event: event.value.id,
      })
      .then((response) => {
        showPaymentModal.value = false;
        window.location.reload();
      })
      .catch((err) => {
        loading.value = false;
      });
  });
};
const onInputFocus = (event: any) => {
  event.preventDefault();
};
</script>

<style scoped>

</style>

<template>

</template>
