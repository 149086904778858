import { ref } from "vue";
import { defineStore } from "pinia";

export const useMemberStore = defineStore("member", () => {
  const id = ref("");
  const name = ref("");
  const address = ref("");
  const phone_no = ref("");
  const category = ref<any>({});

  return { id, name, address, phone_no, category };
});
