<script setup>
import { ref, onMounted, watch, watchEffect } from "vue";
import apiClient from "@/utils/apiClient";
import router from "@/router";
import { useParticipantStore } from "@/stores/eventParticipant";
import { usePageStyleStore } from "@/stores/pageStyle";
import dayjs from "dayjs";
// import timezone from "dayjs/plugin/timezone";
// import utc from "dayjs/plugin/utc";
// import { useRoute } from "vue-router";
import _ from "lodash";
import { useRoute } from "vue-router";
import naraLandscape from "@/assets/img/JSM Community-01 (1).jpg";
import naraLocation from "@/assets/img/JSM Community Address-03.jpg";
import naraPotrait from "@/assets/img/JSM Community-02 (1).jpg";

// const use_route = useRoute();
const id = import.meta.env.VITE_EVENT_ID;
const API_URL = import.meta.env.VITE_API_URL;
const KEY = import.meta.env.VITE_KEY;
const COMITTEE_URL = import.meta.env.VITE_COMITTEE_LINK;
const NEW_FEAT = import.meta.env.VITE_NEW_FEATURE;

const participantData = useParticipantStore();
const pageStyle = usePageStyleStore();

const msg = ref("");

const route = useRoute();
// todo: please delete this check
const checkKrk = ref(route.query?.event?.includes("catholic-revival"));
const checkNara = ref(route.query?.event?.includes("nara-medika"));
const checkPotrait = ref(screen.availHeight > screen.availWidth);

const flow = ref(["year", "month", "calendar"]);
const format = (date) => {
  const day = date.getDate();
  //   const month = date.getMonth() + 1;
  const month = date.toLocaleString("id-ID", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const loading = ref(false);
const openForm = ref(false);

const room_type_option = ref([]);

const questions = ref([]);
const form = ref([]);
const fileForm = ref([]);
const otherOptionForm = ref([]);
const event = ref({});

const source = ref([]);
const sources = ref([
  "Teman",
  "Pasangan / Pacar",
  "Keluarga",
  "Gereja",
  "Sosmed / Media Cetak",
  "Lainnya",
]);

const alreadyKnow = ref(null);
const contactConsent = ref(null);
const paymentProgress = ref(0);

const errResponse = ref({});
const errCode = ref(null);
const openScanner = ref(false);
const memberUrl = ref("");
const captureValue = ref(false);
const linkedMember = ref({});
const packages = ref([]);
const pckg = ref({});

const comiteeForm = ref(false);

// available state: success, loading, failed
const uploadState = ref("success");
const showModal = ref(false);
const showPaymentModal = ref(false);

const pageState = ref(1);

const promo = ref({});
const paymentType = ref([]);
const promoCode = ref("");
const checkPromoBool = ref(false);
const eventLoading = ref(false);

const toggleScan = (val) => {
  openScanner.value = val;
};

const getDate = (date, timezone = 7) => {
  const dateCalc = dayjs(date).add(timezone, "hour").format("DD MMMM YYYY");

  return dateCalc;
};

const getBooleanCondition = (condition) => {
  return condition == true ? "1" : "0";
};

const onDecode = (memberId) => {
  console.log(memberId);
  memberUrl.value = memberId;

  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .get(API_URL + "/api/memberFind", {
        params: {
          member_id: memberId,
          dataKey: KEY,
        },
      })
      .then((response) => {
        linkedMember.value = response.data.member;
      })
      .catch((error) => console.log(error));
  });
};

onMounted(() => {
  eventLoading.value = true;
  loading.value = true;
  comiteeForm.value = route.path == COMITTEE_URL;

  apiClient
    .get(
      API_URL +
        "/api/event/" +
        `${route.query.event ? `search/${route.query.event}` : id}`,
    )
    .then((response) => {
      if (response.data.event.event_launch == 0) {
        loading.value = false;
      }
      openForm.value = true;
      event.value = response.data.event;
      pageStyle.bg = response.data.event.bg_url;
      pageStyle.logo = response.data.event.logo_url;
      pageStyle.color = response.data.event.color;
      eventLoading.value = false;
      response.data.event.payment_type.forEach((type) => {
        paymentType.value.push(type.alias);
      });
    })
    .then(() => {
      if (event.value.event_launch == 1) {
        apiClient
          .get(API_URL + "/api/questionEvent/" + event.value.id)
          .then((response) => {
            questions.value = response.data.question;
            resetForm(response.data.question, 1);
          });
        apiClient
          .get(API_URL + "/api/package/" + event.value.id)
          .then((response) => {
            packages.value = response.data.packages.filter((data) => {
              return comiteeForm.value
                ? data.show_comittee == 1
                : data.show_participant == 1;
            });
            pckg.value = packages.value.length == 1 ? packages.value[0] : {};
          })
          .then(() => {
            loading.value = false;
          });
      }
    })
    .catch((error) => console.log(error));
});

const resetForm = (q, amount) => {
  promo.value = {};
  promoCode.value = "";
  // reset form
  form.value = [];
  otherOptionForm.value = [];
  fileForm.value = [];
  for (let i = 0; i < amount; i++) {
    form.value.push({});
    otherOptionForm.value.push({});
    fileForm.value.push({ url: null });
    q.forEach((element) => {
      form.value[i][element.question?.name] = null;
      otherOptionForm.value[i][element.question?.name] = null;
      if (element.question.type == "file") {
        fileForm.value[i][element.question?.name] = {
          url: null,
        };
      }
    });
  }
};

const onFileChange = (name, e, i) => {
  uploadState.value = "loading";
  showModal.value = true;
  let files = e.target.files || e.dataTransfer.files;

  let fd = new FormData();
  fd.append("image", e.target.files[0]);
  fd.append("question_name", name);
  fd.append("event", event.value.event_name);

  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/participant/uploadPhoto", fd)
      .then((response) => {
        form.value[i][name] = response.data.url;
        uploadState.value = "success";
        setTimeout(() => {
          showModal.value = false;
        }, 5000);
      })
      .catch((err) => {
        uploadState.value = "failed";
        errCode.value = err.response.status;
        errResponse.value = err.response.data;
        setTimeout(() => {
          errCode.value = null;
          showModal.value = false;
        }, 5000);
      });
  });
};

const checkRequired = () => {
  let disabled = false;

  let reqQuestion = questions.value.filter((data) => {
    return (
      data.required == 1 &&
      (comiteeForm.value ? data.show_comittee == 1 : data.show_participant == 1)
    );
  });

  if (Object.keys(pckg.value).length == 0) disabled = true;

  for (let i = 0; i < form.value.length; i++) {
    reqQuestion.forEach((req) => {
      const q = Object.entries(form.value[i]).find(([key, element]) => {
        return req.question.name === key;
      });
      if (q[1] === null || q[1] === "") {
        disabled = true;
      }
    });
  }

  // this is only activated if in option, user pick 'other **'
  let reqOptQuestion = questions.value.filter((data) => {
    return (
      data.required == 1 &&
      (comiteeForm.value
        ? data.show_comittee == 1
        : data.show_participant == 1) &&
      data?.question?.type == "option"
    );
  });

  for (let i = 0; i < otherOptionForm.value.length; i++) {
    reqOptQuestion.forEach((req) => {
      const [code, label] = Object.entries(otherOptionForm.value[i]).find(
        ([key, element]) => {
          return req.question.name === key;
        },
      );
      if (
        form.value[i][code] !== null &&
        form.value[i][code]?.code.includes("other") &&
        (label === null || label === "")
      ) {
        disabled = true;
      }
    });
  }

  return disabled;
};

const checkRequiredPage = () => {
  let disabled = false;

  if (source.value.length == 0 || !alreadyKnow.value || !contactConsent.value)
    disabled = true;

  return disabled;
};

const sendNotif = () => {
  let checkEmail = questions.value.filter((data) => {
    return data.required == 1 && data.question.name == "email";
  });
  let checkPhone = questions.value.filter((data) => {
    return data.required == 1 && data.question.name == "phone_number";
  });
  if (checkEmail.length > 0) {
    apiClient
      .post(
        API_URL + "/api/participant/sendEventMailPublic/" + event.value.id,
        { code: participantData.enc },
      )
      .then((response) => {
        console.log(response);
      });
  }
  if (checkPhone.length > 0) {
    apiClient
      .post(API_URL + "/api/sendWhatsappPublic/" + event.value.id, {
        code: participantData.enc,
      })
      .then((response) => {
        console.log(response);
      });
  }
};

const sendData = () => {
  errCode.value = null;
  errResponse.value = {};

  loading.value = true;

  const promoData = {
    promo_code: promo.value?.promo_code || null,
    price_after: priceCalc(
      pckg.value.price,
      promo.value.type,
      promo.value.number,
    ),
  };

  const arr = ref([]);

  Object.entries(form.value).forEach(([keyA, participant]) => {
    const jData = ref({});

    Object.entries(participant).forEach(([key, element]) => {
      let q = questions.value.find((data) => {
        return data.question.name === key;
      });

      if (element !== null) {
        if (q?.question?.type == "phone") {
          const x = element[0] === "0" ? element.substr(1) : element;
          jData.value[key] = "62" + x;
        } else if (q?.question.type == "option") {
          jData.value[key] = element.code.includes("other")
            ? otherOptionForm.value[keyA][key]
            : element.code;
        } else if (q?.question.type == "checkbox") {
          jData.value[key] = getBooleanCondition(element);
        } else {
          jData.value[key] = element;
        }
      }
    });
    arr.value.push(jData.value);
  });

  const sentData = {
    sentData: arr.value,
    event: event.value.id,
    package: pckg.value.id,
    promo: promoData,
    is_committee: comiteeForm.value,
    source: source.value,
    already_know: alreadyKnow.value == "No" ? false : true,
    contact_consent: contactConsent.value == "No" ? false : true,
  };

  msg.value = "a";
  paymentProgress.value = 1;

  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/participant", sentData)
      .then((response) => {
        msg.value = "b";
        paymentProgress.value = 2;
        participantData.event = response.data.event;
        participantData.data = response.data.participant;
        participantData.pckg = response.data.package;
        participantData.promo = response.data.promo;
        participantData.enc = response.data.enc;

        // check if payment option is available, if not then skip
        if (event.value.payment_type.length > 0) {
          // continue payment
          if (participantData.promo?.price_after > 0) {
            apiClient
              .put(API_URL + "/api/payment/" + response.data.payment, {
                payment: {
                  id: response.data.payment,
                  total_price: promoData.price_after,
                  pckg: response.data.package,
                  type: paymentType.value,
                },
              })
              .then((response) => {
                msg.value = "c";
                paymentProgress.value = 3;

                window.snap.pay(response.data.payment.snap_token, {
                  onSuccess: function (result) {
                    paymentProgress.value = 0;
                    apiClient
                      .post(
                        API_URL +
                          "/api/participant/updatePaid/" +
                          event.value.id,
                        { code: participantData.enc },
                      )
                      .then((response) => {});
                    // sendNotif();
                    router.push({
                      path: "/eventThankYou",
                      query: { event: route.query.event },
                    });
                    loading.value = false;
                  },
                  onPending: function (result) {
                    console.warn(result);
                    paymentProgress.value = 0;
                    // showPaymentModal.value = true;
                    /* You may add your own implementation here */
                  },
                  onError: function (result) {
                    console.err(result);
                    showPaymentModal.value = true;
                    paymentProgress.value = 0;
                    /* You may add your own implementation here */
                  },
                  onClose: function () {
                    showPaymentModal.value = true;
                    paymentProgress.value = 0;
                    // apiClient
                    //   .post(
                    //     API_URL +
                    //       "/api/participant/sendEventMailPublic/" +
                    //       event.value.id,
                    //     { code: participantData.enc }
                    //   )
                    //   .then((response) => {
                    //     console.log(response);
                    //   });
                    /* You may add your own implementation here */
                  },
                });
                // set up: update data on admin page
              });
          } else {
            /* You may add your own implementation here */
            sendNotif();
            router.push({
              path: "/eventThankYou",
              query: { event: route.query.event },
            });
            loading.value = false;
            apiClient.post(
              API_URL + "/api/participant/updatePaid/" + event.value.id,
              {
                code: participantData.enc,
              },
            );
            // set up: update data on admin page
          }
        } else {
          router.push({
            path: "/eventThankYou",
            query: { event: route.query.event },
          });

          loading.value = false;
        }
      })
      .catch((err) => {
        loading.value = false;
        uploadState.value = "failed";
        showModal.value = true;
        errCode.value = err.response.status;
        errResponse.value = err.response.data;
        setTimeout(() => {
          errCode.value = null;
          showModal.value = false;
        }, 5000);
      });
  });
};

const checkPromo = () => {
  const form = {
    code: promoCode.value.toLocaleUpperCase(),
    package: pckg.value.id,
    event: event.value.id,
    is_committee: comiteeForm.value,
  };
  uploadState.value = "loading";
  checkPromoBool.value = true;
  showModal.value = true;

  apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
    apiClient
      .post(API_URL + "/api/promo/checkPromo", form)
      .then((response) => {
        promo.value = response.data.promo;
        uploadState.value = "success";
        setTimeout(() => {
          showModal.value = false;
          checkPromoBool.value = false;
        }, 5000);
      })
      .catch((error) => {
        errCode.value = error.response.status;
        errResponse.value = error.response.data;
        showModal.value = true;
        uploadState.value = "failed";
      });
  });
};

watch(pckg, () => {
  resetForm(questions.value, pckg?.value?.people_register);
});

watch(pckg, () => {
  if (pckg.value == null) pckg.value = {};
});

const formatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const priceCalc = (price, type, promoNum) => {
  switch (type) {
    case "percent":
      return price - (price * promoNum) / 100;
    case "price":
      return price - promoNum;
    default:
      return price;
  }
};

const failedPaymentCleanUp = () => {
  // apiClient.get(API_URL + "/sanctum/csrf-cookie").then((response) => {
  //   apiClient
  //     .post(API_URL + "/api/participant/destroyPublic", {
  //       promo_code: participantData.promo.promo_code,
  //       code: participantData.enc,
  //       event: event.value.id,
  //     })
  //     .then((response) => {
  showPaymentModal.value = false;
  window.location.reload();
  // })
  // .catch((err) => {
  //   loading.value = false;
  // });
  // });
};
</script>

<template v-if="eventLoading">
  <input
    type="checkbox"
    id="my-modal"
    class="modal-toggle"
    :checked="showModal"
    @click="() => {}"
  />

  <div class="modal">
    <div class="modal-box" v-if="errCode == 412">
      <h3 class="text-xl text-center text-red-900">
        Ada Kesalahan dalam pengisian data
        <span class="font-bold">Peserta {{ errResponse.position + 1 }}</span>
        !
      </h3>

      <p class="font-bold mt-2">Harap periksa error message ini:</p>
      <p v-for="(error, index) in errResponse.error" :key="index">
        <span v-for="(err, indexB) in error" :key="indexB">- {{ err }}</span>
      </p>
      <div class="modal-action">
        <label
          for="my-modal"
          class="btn"
          @click="
            () => {
              showModal = false;
              // errCode = null;
              // errResponse = {};
            }
          "
          >OK</label
        >
      </div>
    </div>
    <div class="modal-box" v-else>
      <div class="w-full" v-if="checkPromoBool">
        <h3
          class="font-bold text-xl text-center"
          v-if="uploadState == 'loading'"
        >
          Mengecek Promo
        </h3>
        <h3
          class="font-bold text-xl text-center text-red-600"
          v-if="uploadState == 'failed'"
        >
          Error Kode Promo
        </h3>
        <h3
          class="font-bold text-xl text-center text-green-600"
          v-if="uploadState == 'success'"
        >
          Kode Promo berhasil dipasang!
        </h3>
        <div v-if="uploadState == 'loading'">
          <div
            class="flex space-x-2 animate-pulse items-center justify-center h-32"
          >
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
          </div>
          <p class="text-center">Harap Menunggu ...</p>
        </div>
        <p class="py-4" v-if="uploadState == 'failed'">
          {{ errResponse.message }}
        </p>
        <p class="py-4" v-if="uploadState == 'success'">
          Silahkan menekan tombol dibawah
        </p>
        <div class="modal-action">
          <label
            v-if="uploadState !== 'loading'"
            for="my-modal"
            class="btn"
            @click="
              () => {
                showModal = false;
                checkPromoBool = false;
              }
            "
            >OK</label
          >
        </div>
      </div>
      <div class="w-full" v-else>
        <h3
          class="font-bold text-xl text-center"
          v-if="uploadState == 'loading'"
        >
          Uploading
        </h3>
        <h3
          class="font-bold text-xl text-center text-red-600"
          v-if="uploadState == 'failed'"
        >
          Upload Gagal !
        </h3>
        <h3
          class="font-bold text-xl text-center text-green-600"
          v-if="uploadState == 'success'"
        >
          Upload Berhasil !
        </h3>
        <div v-if="uploadState == 'loading'">
          <div
            class="flex space-x-2 animate-pulse items-center justify-center h-32"
          >
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
            <div class="w-6 h-6 bg-gray-500 rounded-full"></div>
          </div>
          <p class="text-center">Harap Menunggu ...</p>
        </div>
        <p class="py-4" v-if="uploadState == 'failed'">
          Ukuran File terlalu besar (lebih dari 10MB)
        </p>
        <p class="py-4" v-if="uploadState == 'success'">
          Silahkan menekan tombol dibawah
        </p>
        <div class="modal-action">
          <label
            v-if="uploadState !== 'loading'"
            for="my-modal"
            class="btn"
            @click="
              () => {
                showModal = false;
              }
            "
            >OK</label
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Put this part before </body> tag -->
  <input
    type="checkbox"
    id="my_modal_7"
    class="modal-toggle"
    :checked="showPaymentModal"
  />
  <div class="modal">
    <div class="modal-box">
      <h3 class="text-xl font-bold text-red-900 text-center">
        Transaction Failed
      </h3>
      <p class="py-4">
        <span class="font-bold">Terjadi masalah pada pembayaran. </span>
        <br />Bila anda telah melakukan pembayaran, Silahkan menghubungi admin
        registrasi untuk penanganan lebih lanjut <br />Silahkan menekan tombol
        dibawah untuk mengulang registrasi
      </p>
      <button class="btn btn-error btn-block" @click="failedPaymentCleanUp">
        Ulang Registrasi
      </button>
    </div>
    <!-- <label class="modal-backdrop" for="my_modal_7">Ulang Registrasi</label> -->
  </div>

  <dialog id="my_modal_2" class="modal">
    <form method="dialog" class="modal-box">
      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
        ✕
      </button>
      <h3 class="font-bold text-lg text-center mb-5">
        List Paket <br />MCU BY NARA MEDIKA X JSM
      </h3>
      <img :src="naraPotrait" />
    </form>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>

  <dialog id="my_modal_3" class="modal">
    <form method="dialog" class="modal-box">
      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
        ✕
      </button>
      <h3 class="font-bold text-lg text-center mb-5">Lokasi NARA MEDIKA</h3>
      <img :src="naraLocation" />
    </form>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>

  <!-- main start here -->
  <main
    class="w-full lg:w-1/2 mx-auto min-h-screen"
    :class="`bg-[url('${event.bg_url}')] bg-fixed bg-center bg-no-repeat bg-cover`"
    v-if="openForm && !loading"
    v-bind:style="{ 'background-image': 'url(' + event.bg_url + ')' }"
  >
    <div class="flex items-center flex-col justify-center py-5 px-5">
      <h2
        class="mx-auto text-lg md:text-xl text-center font-bold"
        :style="{ color: event.color }"
      >
        {{
          [9, 10, 11, 12].includes(event.id)
            ? "BADAN PELAYANAN NASIONAL"
            : "JEDUTHUN SALVATION MINISTRY"
        }}
      </h2>
      <img :src="event.logo_url || '/CYRLogo.png'" class="w-3/4 md:w-1/2" />
    </div>
    <p class="mx-auto text-lg text-center" :style="{ color: event.color }">
      {{ getDate(event.event_start, event.timezone) }}
      -
      {{ getDate(event.event_end, event.timezone) }}
    </p>
    <h2
      class="font-bold text-2xl mx-auto text-center"
      :style="{ color: event.color }"
    >
      FORM REGISTRASI
      <span>{{ comiteeForm ? "PANITIA" : "PESERTA" }}</span>
    </h2>
    <h3
      class="font-bold text-xl mx-auto text-center"
      :style="{ color: event.color }"
    >
      {{ event.event_name }}
    </h3>
    <p
      class="text-base mx-auto text-center my-2"
      :style="{ color: event.color }"
    >
      {{ event.event_detail }}
    </p>

    <div v-if="!loading && event.event_launch == 1" class="card shadow-xl mt-5">
      <div v-if="checkKrk && pageState == 1">
        <div class="card-body bg-white rounded-xl mb-5">
          <div class="w-full text-lg my-2 font-bold">
            Dari mana anda mengetahui event ini?
            <span class="text-red-900">
              *
              <span class="text-xs">(Harus Isi)</span>
            </span>
          </div>
          <FormKit
            v-model="source"
            type="checkbox"
            :options="sources"
            validation="required"
            wrapper-class="flex"
            input-class="checkbox"
            label-class="ml-1"
          />
          <div class="w-full text-lg my-2 font-bold">
            Apakah anda pernah mendengar Komunitas JSM?
            <span class="text-red-900">
              *
              <span class="text-xs">(Harus Isi)</span>
            </span>
          </div>
          <FormKit
            type="radio"
            v-model="alreadyKnow"
            wrapper-class="flex"
            input-class="radio"
            label-class="ml-1"
            :options="['Yes', 'No']"
          />
          <div class="w-full text-lg my-2 font-bold">
            Apakah anda mau tau lebih lanjut tentang Komunitas JSM?
            <span class="text-red-900">
              *
              <span class="text-xs">(Harus Isi)</span>
            </span>
          </div>
          <FormKit
            type="radio"
            v-model="contactConsent"
            wrapper-class="flex"
            input-class="radio"
            label-class="ml-1"
            :options="['Yes', 'No']"
          />
        </div>
        <button
          class="btn btn-info mt-3 w-full btn-lg"
          @click="
            () => {
              pageState = 2;
            }
          "
          :class="{ 'btn-disabled': checkRequiredPage() }"
          v-if="checkKrk"
        >
          {{
            [9, 10, 11, 12].includes(event.id)
              ? "Let's Register Yourself"
              : "Continue"
          }}
        </button>
        <p class="text-right text-base mt-2">Ke Halaman Selanjutnya</p>
      </div>
      <div v-else class="w-full">
        <button
          class="btn btn-sm mb-2 btn-warning"
          @click="
            () => {
              pageState = 1;
            }
          "
          v-if="checkKrk"
        >
          {{ "<- Kembali" }}
        </button>
        <div class="card-body bg-white rounded-xl">
          <div class="w-full text-lg my-2 font-bold" v-if="packages.length > 1">
            Pilih Paket
          </div>
          <v-select
            v-if="packages.length > 1"
            v-model="pckg"
            :options="packages"
            :label="'package_name'"
            class="input w-full w-3/4 px-0"
            :validation="'required'"
          ></v-select>
          <button
            class="btn btn-info btn-outline w-full"
            v-if="checkNara"
            onclick="my_modal_2.showModal()"
          >
            Cek Paket <br />MCU BY NARA MEDIKA X JSM
          </button>
          <button
            class="btn btn-success btn-outline w-full"
            v-if="checkNara"
            onclick="my_modal_3.showModal()"
          >
            Cek Lokasi NARA MEDIKA
          </button>
          <div
            class="w-full text-lg my-2"
            v-if="Object.keys(pckg).length > 0 || pckg == null"
          >
            <div class="flex" v-if="packages.length > 1">
              <div class="w-1/2">Nama Paket</div>
              <div class="font-bold">{{ pckg.package_name }}</div>
            </div>
            <div class="flex" v-if="!_.isEmpty(promo)">
              <div class="w-1/2">Promo</div>
              <div class="font-bold">{{ promo.promo_code }}</div>
            </div>
            <div class="flex" v-if="!_.isEmpty(promo)">
              <div class="w-1/2">Nilai Promo</div>
              <div class="font-bold">
                <span v-if="promo.type == 'price'">{{
                  formatter.format(promo.number) + ",-"
                }}</span>
                <span v-if="promo.type == 'percent'">{{ promo.number }}%</span>
              </div>
            </div>
            <div class="flex">
              <div class="w-1/2">Harga</div>
              <div class="font-bold" v-if="_.isEmpty(promo)">
                {{ formatter.format(pckg.price) + ",-" }}
              </div>
              <div class="font-bold" v-else>
                <div>
                  <p class="text-xs line-through">
                    {{ formatter.format(pckg.price) + ",-" }}
                  </p>
                </div>
                <div class="text-red-600">
                  {{
                    formatter.format(
                      priceCalc(pckg.price, promo.type, promo.number),
                    ) + ",-"
                  }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="w-1/2">Peserta</div>
              <div class="font-bold">{{ pckg.people_register }} Orang</div>
            </div>

            <div class="w-full mt-3" v-if="event.payment_type.length > 0">
              Kode Promo ?
              <span class="text-xs">(Optional)</span>
            </div>
            <!-- <div class="flex w-full"> -->
            <div class="w-full mb-2" v-if="event.payment_type.length > 0">
              <input
                type="text"
                v-model="promoCode"
                placeholder="Kode Promo"
                class="input input-bordered w-full uppercase"
              />
            </div>
            <button
              class="btn btn-info w-full btn-lg"
              @click="checkPromo"
              v-if="event.payment_type.length > 0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-6 h-6"
              >
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>
              Cari Kode Promo
            </button>
          </div>
          <!-- </div> -->
        </div>
        <div v-if="Object.keys(pckg).length > 0 || pckg == null">
          <div
            class="card-body bg-white rounded-xl mt-3"
            v-for="n in pckg.people_register"
            :key="n"
          >
            <p class="font-bold text-lg">Peserta {{ n }}</p>
            <div
              class="items-center"
              v-for="(question, index) in questions"
              v-bind:key="index"
            >
              <div
                class="w-full my-2"
                v-if="
                  comiteeForm
                    ? question.show_comittee == 1
                    : question.show_participant == 1
                "
              >
                <div class="w-full text-lg">
                  {{ question?.question?.title }}
                  <span v-if="question.required" class="text-red-900">
                    *
                    <span class="text-xs">(Harus Isi)</span>
                  </span>
                  <span v-else class="text-xs">(Optional)</span>
                </div>
                <div class="w-full">
                  <Datepicker
                    v-if="question?.question?.type == 'datetime'"
                    v-model="form[n - 1][question?.question?.name]"
                    :max-date="new Date().setFullYear(new Date().getFullYear())"
                    :enable-time-picker="false"
                    :format="format"
                    :flow="flow"
                    timezone="Asia/Jakarta"
                    auto-apply
                    utc
                    prevent-min-max-navigation
                    :start-date="
                      new Date().setFullYear(new Date().getFullYear() - 20)
                    "
                    locale="id-ID"
                  >
                  </Datepicker>
                  <div
                    v-else-if="question?.question?.type == 'phone'"
                    class="w-full flex items-center"
                  >
                    <span class="mr-2 font-bold">+62</span>
                    <FormKit
                      v-model="form[n - 1][question?.question?.name]"
                      input-class="input input-bordered w-full"
                      type="tel"
                      outer-class="w-full"
                      ::placeholder="question?.question?.title"
                      :validation="[
                        ['required'],
                        [
                          'matches',
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        ],
                      ]"
                      :validation-messages="{
                        matches: 'Phone number must be in the format',
                      }"
                      validation-visibility="dirty"
                      scrollBehavior="auto"
                    />
                  </div>
                  <div
                    v-else-if="question?.question?.type == 'option'"
                    class="w-full"
                  >
                    <v-select
                      v-model="form[n - 1][question?.question?.name]"
                      :options="
                        _.flatten(
                          Object.values(JSON.parse(question?.question?.option)),
                        )
                      "
                      class="input w-full px-0"
                      :validation="
                        question.required &&
                        (comiteeForm
                          ? question.show_comittee == 1
                          : question.show_participant == 1)
                          ? 'required'
                          : ''
                      "
                    ></v-select>
                    <div
                      class="mt-5"
                      v-if="form[n - 1][question?.question?.name] !== null"
                    >
                      <p
                        class="text-base font-semibold text-gray-600"
                        v-if="
                          form[n - 1][question?.question?.name].code.includes(
                            'other',
                          )
                        "
                      >
                        Silahkan mengisi {{ question?.question?.title }} anda
                      </p>
                      <FormKit
                        v-if="
                          form[n - 1][question?.question?.name].code.includes(
                            'other',
                          )
                        "
                        :type="'text'"
                        v-model="
                          otherOptionForm[n - 1][question?.question?.name]
                        "
                        :placeholder="question?.question?.title"
                        input-class="input input-bordered w-full"
                        :validation="
                          question.required &&
                          (comiteeForm
                            ? question.show_comittee == 1
                            : question.show_participant == 1)
                            ? 'required'
                            : ''
                        "
                        :validation-messages="{
                          required:
                            'Harap mengisi ' + question?.question?.title,
                        }"
                        scrollBehavior="auto"
                      />
                    </div>
                  </div>
                  <v-select
                    v-else-if="question?.question?.type == 'room'"
                    v-model="form[n - 1][question?.question?.name]"
                    :options="room_type_option"
                    class="input w-full w-3/4 px-0"
                    :validation="
                      question.required &&
                      (comiteeForm
                        ? question.show_comittee == 1
                        : question.show_participant == 1)
                        ? 'required'
                        : ''
                    "
                  ></v-select>
                  <div
                    v-else-if="question?.question?.type == 'file'"
                    class="w-full mb-3"
                  >
                    <img
                      v-if="form[n - 1][question.question.name] !== null"
                      :src="form[n - 1][question.question.name]"
                    />
                    <input
                      type="file"
                      class="file-input w-full max-w-xs"
                      :required="
                        question.required &&
                        (comiteeForm
                          ? question.show_comittee == 1
                          : question.show_participant == 1)
                      "
                      v-on:change="
                        (event) =>
                          onFileChange(question?.question?.name, event, n - 1)
                      "
                    />
                  </div>
                  <div v-else-if="question?.question?.type == 'radio'">
                    <FormKit
                      :type="question?.question?.type"
                      v-model="form[n - 1][question?.question?.name]"
                      input-class="radio checked:bg-blue-500"
                      wrapper-class="flex"
                      label-class="ml-3"
                      :validation="
                        question.required &&
                        (comiteeForm
                          ? question.show_comittee == 1
                          : question.show_participant == 1)
                          ? 'required'
                          : ''
                      "
                      :options="
                        _.flatten(
                          Object.values(JSON.parse(question?.question?.option)),
                        )
                      "
                    />
                  </div>
                  <FormKit
                    v-else-if="question?.question?.type == 'checkbox'"
                    :type="question?.question?.type"
                    v-model="form[n - 1][question?.question?.name]"
                    input-class="checkbox checkbox-lg checkbox-primary"
                    :validation="
                      question.required &&
                      (comiteeForm
                        ? question.show_comittee == 1
                        : question.show_participant == 1)
                        ? 'required'
                        : ''
                    "
                  />
                  <FormKit
                    v-else
                    :type="question?.question?.type"
                    v-model="form[n - 1][question?.question?.name]"
                    :placeholder="question?.question?.title"
                    input-class="input input-bordered w-full"
                    :validation="
                      question.required &&
                      (comiteeForm
                        ? question.show_comittee == 1
                        : question.show_participant == 1)
                        ? 'required'
                        : ''
                    "
                    :validation-messages="{
                      required: 'Harap mengisi ' + question?.question?.title,
                    }"
                    scrollBehavior="auto"
                  />
                </div>
              </div>
              <div
                v-if="!_.isEmpty(errResponse) && errCode === 422"
                class="flex items-center"
              >
                <div class="w-1/4"></div>
                <div
                  v-if="
                    Array.isArray(errResponse.errors[question?.question?.name])
                  "
                >
                  <div
                    v-for="(err, index) in errResponse.errors[
                      question?.question?.name
                    ]"
                    v-bind:key="index"
                  >
                    <p class="text-red-600">{{ err }}</p>
                  </div>
                </div>
              </div>
              <p
                v-if="
                  comiteeForm
                    ? question.show_comittee == 1
                    : question.show_participant == 1
                "
                class="text-xs font-semibold text-gray-600"
                v-html="question.question.detail"
              ></p>
            </div>
          </div>
        </div>
        <button
          class="btn btn-success mt-3 w-full btn-lg"
          @click="sendData"
          :class="{ 'btn-disabled': checkRequired() }"
        >
          Register
        </button>
      </div>
      <div v-if="checkKrk" class="w-full">
        <p class="text-center text-xl my-2">
          Halaman <span class="font-bold">{{ pageState }}</span> dari 2
        </p>
      </div>
    </div>
    <div v-else-if="!loading && event.event_launch == 0">
      <div class="flex justify-center w-full pt-10 min-h-screen">
        <p class="text-3xl text-center" :style="{ color: event.color }">
          Maaf, Pendaftaran telah ditutup
        </p>
        <!-- <span class="loading loading-dots loading-lg"></span> -->
      </div>
    </div>
    <div v-else class="min-h-screen">
      <div class="flex items-center justify-center w-full pt-10 min-h-screen">
        <p class="text-3xl ml-4">Harap Menunggu</p>
        <span class="loading loading-dots loading-lg"></span>
      </div>
    </div>
  </main>
  <div class="w-full h-full min-h-screen" v-else-if="loading && openForm">
    <div class="flex flex-col items-center justify-center w-full h-full pt-10">
      <p class="text-3xl ml-4">Harap Menunggu</p>
      <span class="loading loading-dots loading-lg"></span>
      <div class="w-full px-10" v-if="paymentProgress > 0">
        <progress
          class="progress w-full"
          :value="paymentProgress"
          :max="3"
        ></progress>
      </div>
    </div>
  </div>
</template>
