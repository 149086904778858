import { ref } from "vue";
import { defineStore } from "pinia";

export const useParticipantStore = defineStore("participant", () => {
  const data = ref<any>({});
  const event = ref<any>({});
  const pckg = ref<any>({});
  const promo = ref<any>({});
  const enc = ref<any>("");

  return { data, event, pckg, promo, enc };
});
