<script setup lang="ts">
import QrcodeVue from "qrcode.vue";
import { ref, onMounted } from "vue";
import { useMemberStore } from "@/stores/member";
import { jsPDF } from "jspdf";
import apiClient from "@/utils/apiClient";
import { useRoute } from "vue-router";

const use_route = useRoute();
// const id = import.meta.env.VITE_EVENT_ID;
const id = use_route.params.id || import.meta.env.VITE_EVENT_ID;
const uid = use_route.params.uid;
const API_URL = import.meta.env.VITE_API_URL;

const memberState = useMemberStore();
const mode = ref("sc");
const participant = ref<any>({});
const event = ref<any>({});

const err = ref(false);
const errorMsg = ref("");

const showContent = (pickedContent: string) => {
  mode.value = pickedContent;
};

const isDarkMode = () =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

onMounted(() => {
  apiClient
    .get(API_URL + "/api/participant/getData/" + id + "/" + uid)
    .then((response) => {
      participant.value = response.data.participant;
      console.log(response.data.participant);
      event.value = response.data.event;
    })
    .catch((error) => {
      err.value = true;
      errorMsg.value =
        error.response?.data?.participant || "Something is Wrong";
      console.log(error.response.data);
    }); // credentials didn't match
  // });
});

const printCard = () => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [90, 55],
  });
  var element: any = document.querySelector("#card");
  doc.html(element, {
    callback: function (doc) {
      // Save the PDF
      doc.save(`${memberState.name} - Kartu JSM.pdf`);
    },
    x: 0,
    y: 1,
    width: 175, //target width in the PDF document
    windowWidth: 650, //window width in CSS pixels
  });
};
</script>

<template>
  <main>
    {{}}
    <h2 class="font-bold mx-auto text-center mb-5 px-4 lg:px-0">
      Here's your card, you can download or screenshot it to be used in the
      future
    </h2>
    <div class="tabs tabs-boxed class flex items-center w-full justify-center">
      <a
        class="tab"
        :class="{ 'tab-active': mode === 'card' }"
        @click="showContent('card')"
        >Card Mode</a
      >
      <a
        class="tab"
        :class="{ 'tab-active': mode === 'sc' }"
        @click="showContent('sc')"
        >Screenshot Mode</a
      >
    </div>
    <div v-if="mode === 'card'">
      <div class="w-full mx-auto flex items-center justify-center px-4">
        <div
          class="w-96 h-48 bg-white border border-rounded border-gray-200"
          id="card"
        >
          <div class="flex items-center justify-center h-full w-full">
            <div class="w-1/2">
              <qrcode-vue
                :value="participant.user_id"
                :foreground="
                 event.color && event.color !== '#FFFFFF' ? event.color : '#7f1d1d'
                "
                class="w-3/5 mx-auto"
                :margin="2"
              ></qrcode-vue>
            </div>
            <div class="w-1/2">
              <!-- <p class="font-bold text-sm">Komunitas Amazing Grace</p> -->
              <p class="font-bold text-red-800">
                {{ event.event_name }}
              </p>
              <p class="font-bold text-xl">{{ participant?.name }}</p>
              <!-- <p class="font-bold">{{ participant.phone_number }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto flex w-full items-center justify-center mt-5">
        <button class="btn btn-success" @click="printCard">Print It!</button>
      </div>
    </div>
    <div v-if="mode === 'sc'">
      <div class="w-full mx-auto flex items-center justify-center px-4">
        <div class="lg:w-1/2 w-full" id="card">
          <div
            v-if="!err"
            class="flex items-center justify-center h-full w-full flex-col px-4"
          >
            <div class="w-full mx-auto">
              <qrcode-vue
                :value="participant.user_id"
                :foreground="
                  event.color && event.color !== '#FFFFFF' ? event.color : '#7f1d1d'
                "
                class="w-3/5 mx-auto"
                :margin="2"
                :size="200"
              ></qrcode-vue>
              <!-- <qrcode-vue
                :value="participant.user_id"
                :foreground="isDarkMode() ? '#FFFFFF' : '#00000'"
                class="mx-auto p-2"
                :size="200"
                render-as="svg"
                :background="isDarkMode() ? '#000' : '#FFFF'"
                :margin="2"
              ></qrcode-vue> -->
            </div>
            <div class="w-full text-center">
              <p class="font-bold text-xl">
                {{ event.event_name }}
              </p>
              <p class="font-bold text-3xl">{{ participant?.name }}</p>
              <!-- <p class="font-bold text-xl">{{ participant.phone_number }}</p> -->
            </div>
          </div>
          <div
            class="w-full text-center mt-2"
            v-if="participant?.participate_approve == 1"
          >
            <p class="text-xl text-red-800">
              Kamar:
              <span class="font-bold">
                {{ participant?.building?.building_name || "-" }} -
                {{ participant?.floor?.floor_name || "-" }} -
                {{ participant?.room?.room_name || "-" }}
              </span>
            </p>
            <p class="text-xl text-red-800">
              Kelompok:
              <span class="font-bold">{{
                participant?.team?.team_name || "-"
              }}</span>
            </p>
          </div>
          <div class="w-full mx-auto" v-else>
            <p class="text-center">{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
