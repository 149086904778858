import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import EventRegisterVue from "@/views/Event/EventRegister.vue";
import EventThankVue from "@/views/Event/EventThank.vue";
import ParticipantQRCard from "@/views/Event/ParticipantQRCard.vue";
import EventIOS from "@/views/Event/EventIOS.vue";

const COMITTEE_URL = import.meta.env.VITE_COMITTEE_LINK;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // {
    //   path: "/",
    //   name: "home",
    //   component: HomeView,
    // },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/",
      name: "eventRegister",
      component: EventRegisterVue,
    },
    {
      path: "/ios",
      name: "EventIOS",
      component: EventIOS,
    },
    {
      // path: "/" + COMITTEE_URL,
      path: "/registerPanitiaCYR2023",
      name: "comitteeRegister",
      component: EventRegisterVue,
    },
    {
      path: "/eventThankYou",
      name: "eventThankYou",
      component: EventThankVue,
    },
    {
      path: "/thankYouPayment",
      name: "thankYouPayment",
      component: EventThankVue,
    },
    {
      path: "/registerCard/:id/:uid",
      name: "registerCard",
      component: ParticipantQRCard,
    },
    {
      path: "/register",
      name: "eventRegisterSlug",
      component: EventRegisterVue,
    },

  ],
});

export default router;
